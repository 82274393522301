import { sortBy } from 'lodash-es';
import { createSelector } from 'reselect';
import { IStore } from 'store/configureStore';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import {
    departmentMappingKey,
    HierarchyNodeOperation,
    HierarchyType,
    ICustomField,
    ICustomFieldHierarchyNode,
    ICustomFieldValue,
    IPrismMappingField,
} from 'store/entities/customFields/model';

export const selectCustomFieldsState = (store: IStore) => store.customFields;

/**
 * Custom fields
 */
export const selectCustomFieldsByIds = (store: IStore): Record<string, ICustomField> => {
    return selectCustomFieldsState(store).customFieldsByIds;
};
export const selectCustomFieldsIds = createSelector(
    selectCustomFieldsByIds,
    customFieldsByIds => Object.keys(customFieldsByIds),
);
export const selectCustomFields = createSelector(
    selectCustomFieldsByIds,
    customFieldsByIds => Object.values(customFieldsByIds ?? {}),
);
export const selectCustomFieldsList = createSelector(
    selectCustomFieldsByIds,
    (customFieldsByIds): ICustomField[] => sortBy(customFieldsByIds, customField => customField.name),
);
export const selectIsLoadingCustomFields = (store: IStore) => selectCustomFieldsState(store).isLoadingCustomFields;
export const selectCustomFieldById = (id: string) => (store: IStore) => selectCustomFieldsByIds(store)[id];

// TODO : Change to export field
export const selectCustomFieldDepartmentId = createSelector(
    selectCustomFields,
    (customFields): string | undefined => {
        return customFields ? customFields.find(x => x.prism_field?.key === departmentMappingKey)?.id : undefined;
    },
);

/**
 * Custom fields mappings
 */
export const selectCustomFieldsMappings = (store: IStore): IPrismMappingField[] =>
    selectCustomFieldsState(store).mappingFields;
export const selectIsLoadingCustomFieldMapping = (store: IStore) =>
    selectCustomFieldsState(store).isLoadingMappingFields;

/**
 * Custom fields hierarchy nodes
 */
export const selectCustomFieldHierarchyNodesByIds = (store: IStore): Record<string, ICustomFieldHierarchyNode> => {
    return selectCustomFieldsState(store).customFieldsHierarchyNodesByIds;
};
export const selectCustomFieldsHierarchyNodesList = createSelector(
    selectCustomFieldHierarchyNodesByIds,
    (customFieldsHierarchyNodesByIds): ICustomFieldHierarchyNode[] => {
        return Object.values(customFieldsHierarchyNodesByIds);
    },
);

export const selectCustomFieldsOrderByIds = createSelector(
    selectCustomFieldsHierarchyNodesList,
    (customFieldsHierarchyNodes: Array<ICustomFieldHierarchyNode>) => {
        const iterate = (previousState: Record<string, number>, item: ICustomFieldHierarchyNode) => {
            return {
                ...previousState,
                [item.custom_field_id]: item.siblings_order,
            };
        };
        return customFieldsHierarchyNodes.reduce(iterate, {});
    },
);

export const selectIsLoadingCustomFieldsHierarchyNodes = (store: IStore) => {
    return selectCustomFieldsState(store).isLoadingCustomFieldsHierarchyNodes;
};
export const selectIsCustomFieldsHierarchySaving = (store: IStore) => {
    return selectCustomFieldsState(store).isCustomFieldsHierarchySaving;
};

export const selectCustomFieldAnchorNode = createSelector(
    selectCustomFieldsHierarchyNodesList,
    nodes => nodes.find(node => node.anchor),
);

export const selectAnchorCustomField = createSelector(
    selectCustomFieldsByIds,
    selectCustomFieldAnchorNode,
    (fieldsByIds, node) => fieldsByIds[node?.custom_field_id || ''],
);
export const selectAnchorCustomFieldId = createSelector(
    selectCustomFieldAnchorNode,
    node => node?.custom_field_id,
);

const sortNodes = (nodes: ICustomFieldHierarchyNode[]) => {
    return nodes.sort((node1, node2) => {
        return node1.siblings_order >= node2.siblings_order ? 1 : -1;
    });
};

const getChildren = (parent: ICustomFieldHierarchyNode, allNodes: ICustomFieldHierarchyNode[]) => {
    const children = allNodes.filter(node => node.parent_id === parent.id);
    return sortNodes(children);
};
export const childrenByNodeIdSelector = createSelector(
    selectCustomFieldsHierarchyNodesList,
    nodes => {
        return nodes.reduce((acc, node) => ({
            ...acc,
            [node.id]: getChildren(node, nodes),
        }), {}) as Record<string, ICustomFieldHierarchyNode[]>;
    },
);

export const clientAssignmentNodesSelector = createSelector(
    selectCurrentClientId,
    selectCustomFieldsHierarchyNodesList,
    (clientId, nodes) => {
        return nodes.filter(node =>
            ((!clientId && !node.client_id) || node.client_id === clientId)
            && node.hierarchy_type === HierarchyType.Assignment,
        );
    },
);

export const rootAssignmentNodesSelector = createSelector(
    selectCurrentClientId,
    clientAssignmentNodesSelector,
    (clientId, clientNodes) => {
        const rootNodes = clientNodes.filter(node => !node.parent_id);
        return sortNodes(rootNodes);
    },
);

const getOrderedHierarchy = (
    rootNodes: ICustomFieldHierarchyNode[],
    childrenNodesByIds: Record<string, ICustomFieldHierarchyNode[]>,
) => {
    const getFlatOrderedNodes = (nodes: ICustomFieldHierarchyNode[]): ICustomFieldHierarchyNode[] => {
        return nodes.reduce((memo, node) => {
            return [
                ...memo,
                node,
                ...getFlatOrderedNodes(childrenNodesByIds[node.id] || []),
            ];
        }, [] as ICustomFieldHierarchyNode[]);
    };
    return getFlatOrderedNodes(rootNodes);
};

/**
 * Select ordered flat nodes list
 */
export const selectOrderedCustomFieldAssignmentNodes = createSelector(
    rootAssignmentNodesSelector,
    childrenByNodeIdSelector,
    getOrderedHierarchy,
);

/**
 * Select ordered flat non hidden nodes list
 */
export const selectOrderedNonHiddenCustomFieldNodes = createSelector(
    selectOrderedCustomFieldAssignmentNodes,
    orderedCustomFieldNodes => {
        return orderedCustomFieldNodes.filter(node => node.operation !== HierarchyNodeOperation.Hidden);
    });

/**
 * Select ordered flat non hidden nodes list
 */
export const selectActionableCustomFieldNodes = createSelector(
    selectOrderedCustomFieldAssignmentNodes,
    orderedCustomFieldNodes => {
        return orderedCustomFieldNodes.filter(node => node.operation === HierarchyNodeOperation.Actionable);
    });

/**
 * Select ordered assignments custom Ids list
 */
export const selectOrderedCustomFieldIds = createSelector(
    selectOrderedCustomFieldAssignmentNodes,
    (nodes): string[] => nodes.map(node => node.custom_field_id),
);

/**
 * Select custom fields to display on grid
 */
export const selectDisplayedOnTableCustomFields = createSelector(
    selectOrderedCustomFieldAssignmentNodes,
    selectCustomFieldsByIds,
    (nodes, customFieldsByIds): ICustomField[] => nodes
        .filter(node => node.display_on_grid)
        .map(node => customFieldsByIds[node.custom_field_id]).filter(Boolean),
);

export const selectFirstTwoRootCustomFields = createSelector(
    selectOrderedCustomFieldAssignmentNodes,
    selectCustomFieldsByIds,
    (customFieldNodes, fieldByIds) => sortBy(
        customFieldNodes.filter(node => node.parent_id === null),
        node => node.siblings_order,
    ).slice(0, 2)
        .map(node => fieldByIds[node.custom_field_id])
        .filter(Boolean),
);

/**
 * Custom field values
 */
export const selectCustomFieldValuesByIds = (store: IStore) =>
    selectCustomFieldsState(store).customFieldValuesByIds;

export const selectIsCustomFieldValuesLoading = (store: IStore) => store.customFields.isQueryingCustomFieldValues
    || selectIsLoadingCustomFieldsHierarchyNodes(store);
const selectAllCustomFieldValues = (store: IStore): ICustomFieldValue[] =>
    Object.values(selectCustomFieldValuesByIds(store));
export const selectAllNotDeletedCustomFieldValues = (store: IStore): ICustomFieldValue[] => {
    const allCustomFieldValues = selectAllCustomFieldValues(store);
    return allCustomFieldValues.filter(fieldValue => !fieldValue.deleted_at);
};
/**
 * Create selector for all not deleted custom field value IDs
 */
export const selectAllNotDeletedCustomFieldValueIds = createSelector(
    selectAllNotDeletedCustomFieldValues,
    allNotDeletedCustomFieldValues => allNotDeletedCustomFieldValues.map(({ id }) => id),
);
export const selectCustomFieldValuesByFieldId = (id: string) => (store: IStore): ICustomFieldValue[] =>
    sortBy(
        selectAllNotDeletedCustomFieldValues(store).filter(value => value.custom_field_id === id),
        fieldValue => fieldValue.data?.name,
    );

/**
 * Select hierarchy nodes by custom field ids
 */
export const selectCustomFieldNodeByFieldId = createSelector(
    selectOrderedCustomFieldAssignmentNodes,
    nodes => nodes.reduce((mem, node) => {
        return {
            ...mem,
            [node.custom_field_id]: node,
        };
    }, {}) as Record<string, ICustomFieldHierarchyNode>,
);

/**
 * Select offer letter fields hierarchy
 */
export const selectOfferLetterHierarchy = createSelector(
    selectCustomFieldsHierarchyNodesList,
    childrenByNodeIdSelector,
    (allNodes, childrentByIds) => {
        const rootNodes = sortNodes(allNodes.filter(
            node => node.hierarchy_type === HierarchyType.OfferLetter
                && node.parent_id === null,
        ));
        return getOrderedHierarchy(
            rootNodes,
            childrentByIds,
        );
    },
);

/**
 * Select offer letter fields
 */
export const selectOrderedOfferLetterCustomFields = createSelector(
    selectOfferLetterHierarchy,
    selectCustomFieldsByIds,
    (nodes, fieldsByIds) => nodes
        .map(node => fieldsByIds[node.custom_field_id]).filter(Boolean),
);

const selectIdCustomFieldIsAlreadyLoadedState = createSelector(
    selectCustomFieldsState,
    state => state.customFieldValuesLoaded,
);
export const selectIdCustomFieldAllValuesIsAlreadyLoaded = (
    fieldId: string,
) => (
    state: IStore,
): boolean => selectIdCustomFieldIsAlreadyLoadedState(state)[fieldId] || false;
