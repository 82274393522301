import {
    isString,
    orderBy,
    uniq,
} from 'lodash-es';
import lookupSagas from 'modules/offerLetter/components/lookup/data/sagas';

import {
    getMyEmployeeProfile,
    getMyEmployeeProfiles,
    onboardMyEmployeeProfile,
} from 'modules/offerLetter/components/OfferLetterEmployeeDetail/store/actions';
import {
    acceptOfferLetterTerms,
    approveOfferPayRange,
    checkEndOfAssignmentPdfStatus,
    checkOfferLetterPdf,
    createOffer,
    editOffer,
    getEmployeeProfiles,
    getMoreOfferLetterAction,
    getMyOfferLetters,
    getOfferLetter,
    getOfferLetters,
    initialLoadOfferLetters,
    rejectOfferPayRange,
    rescindOffer,
    setOfferLetterFilters, setOfferLetterTableSort,
    updateOfferLetter,
    updateOfferLetterStatus,
    viewOfferLetter,
} from 'modules/offerLetter/store/actions';
import { offerLetterApi } from 'modules/offerLetter/store/api';
import {
    ICreateEmployeeOfferLetterBackend,
    ICreateOfferLetterBackend,
    ICreateOfferRequest,
    IGetOfferLetterParams,
    IOfferLetter,
    IOfferLetterFilters,
    IUpdateOfferRequest,
    OfferLetterStatusNameMap,
    OfferLetterStatusSlug,
} from 'modules/offerLetter/store/model';
import {
    offerLettersTableStateSelectors, selectAbsentOfferLetterCustomFieldIds,
    selectAbsentOfferLetterPayRangesIds,
    selectOfferLetter,
    selectOfferLetterFilters, selectOfferLetterTableSort,
} from 'modules/offerLetter/store/selectors';
import moment from 'moment';
import { generatePath, matchPath } from 'react-router';

import { autoHideDefaultDuration, IModalSeverity } from 'shared/components/toasts/modal';
import { SortingOrder } from 'shared/models/Order';
import { PayType } from 'shared/models/PaySettings';
import { routes } from 'shared/routes';
import { browserHistory } from 'shared/utils/browserHistory';
import { getUserName } from 'shared/utils/converters/user';
import { logErrorWithCustomMessage } from 'shared/utils/logging/logger';
import { takeLatestActionByString } from 'shared/utils/sagas';

import { authByPassword, authByToken, authTokenUpdate } from 'store/components/auth/authActions';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';
import { navigateWithClientSaga } from 'store/components/router/routerSagas';
import { IStore } from 'store/configureStore';
import { setGlobalToast } from 'store/entities/appConfig/actions';
import { getClientPaySettings, setClients } from 'store/entities/clients/clientsAction';
import { selectCurrentClientId } from 'store/entities/clients/selectors/clientsSelectors';
import { getPayRanges } from 'store/entities/configuration/configurationAction';
import { getCustomFieldValues } from 'store/entities/customFields/actions';
import { getUsers } from 'store/entities/users/actions';
import { selectUsersById } from 'store/entities/users/selectors';
import { getWildcardMask } from 'store/entities/users/utils';
import { getLoadEntitiesByRequestSagaWatcher } from 'store/utils/sagas/getLoadEntitiesByRequestSagaWatcher';
import { withBackendErrorHandler } from 'store/utils/sagas/withBackendErrorHandler';
import {
    all, call, put, select, spawn, takeEvery, takeLatest,
} from 'typed-redux-saga';

import { IOfferLetterFormValues } from '../components/OfferLetterForm/model';

import offerLetterTemplateSagas from './templates/sagas';

const payRangeSpecificStatuses = [OfferLetterStatusSlug.PendingPayRateApproval, OfferLetterStatusSlug.RejectedPayRate];

function* getJsonSortTuple(selector: (state: IStore) => SortingOrder) {
    const sortOrder = yield select(selector);
    const sort = Object.entries(sortOrder)[0];
    return JSON.stringify(sort);
}

function* getMoreOfferLettersSaga() {
    const range = yield select(offerLettersTableStateSelectors.selectNextRange);
    const offerFilter = yield select(selectOfferLetterFilters);
    const sort = yield getJsonSortTuple(selectOfferLetterTableSort);

    let statusRequest;
    const status = Object.values(OfferLetterStatusSlug)
        .includes(offerFilter.statusSlug) ? offerFilter.statusSlug : undefined;
    if (payRangeSpecificStatuses.includes(offerFilter.statusSlug)) {
        statusRequest = {
            statuses: payRangeSpecificStatuses.join(','),
        };
    } else {
        statusRequest = {
            status,
        };
    }

    let filters = undefined;

    if (offerFilter.startDateGte || offerFilter.startDateLte) {
        const startDateFilter: Record<string, string> = {};
        const startDateMapping: Partial<Record<keyof IOfferLetterFilters, string>> = {
            'startDate': 'eq',
            'startDateGte': 'gte',
            'startDateLte': 'lte',
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        Object.entries(startDateMapping).forEach(([formKey, payloadKey]: [string, string]) => {
            if (offerFilter[formKey]) {
                startDateFilter[payloadKey] = offerFilter[formKey];
            }
        });
        // @ts-ignore
        filters = { start_date: startDateFilter };
    }

    const request: IGetOfferLetterParams = {
        range,
        filters: filters ? JSON.stringify(filters) : undefined,
        job_order_id: offerFilter.jobOrderId || undefined,
        start_date: offerFilter.startDate || undefined,
        creator_id: offerFilter.creatorId || undefined,
        approver_id: offerFilter.approverId || undefined,
        search: offerFilter.search ? getWildcardMask(offerFilter.search) : undefined,
        created_since: offerFilter.created_since || undefined,
        sort,
        ...statusRequest,
    };
    const { offer_letters: offerLetters, total_items: total } = yield* getOfferLettersWithLinkedSaga(request);
    yield put(getMoreOfferLetterAction.success({
        offerLetterIds: offerLetters.map(offer => offer.id),
        total,
    }));
}

function* getOfferLettersWithLinkedSaga(request: IGetOfferLetterParams) {
    const response = yield* call(offerLetterApi.getOfferLetter, request);
    const { offer_letters: offerLetters } = response;

    yield put(getOfferLetters.success(offerLetters));

    const absentPayRangesIds = yield* select(selectAbsentOfferLetterPayRangesIds);
    if (absentPayRangesIds.length) {
        yield put(getPayRanges.init({ ids: absentPayRangesIds.join(',') }));
    }

    const absentCustomFieldValues = yield* select(selectAbsentOfferLetterCustomFieldIds);
    if (absentCustomFieldValues.length) {
        yield put(getCustomFieldValues.init({ custom_field_value_ids: absentCustomFieldValues }));
    }

    const usersByIds = yield* select(selectUsersById);
    const offerUserIds = new Set();
    offerLetters.forEach(offer => {
        offerUserIds.add(offer.creator_id);
        if (offer.rescinded_by !== null) {
            offerUserIds.add(offer.rescinded_by);
        }
    });
    const storeIds = new Set(Object.keys(usersByIds));
    const requestUserIds = Array.from(new Set(
        [...offerUserIds].filter((userId: string) => storeIds.has(userId)),
    ));
    if (requestUserIds.length) {
        yield put(getUsers.init({ ids: requestUserIds.join(',') }));
    }

    return response;
}

function* getMoreOfferLettersWatcher() {
    yield takeLatest([
        getMoreOfferLetterAction.initType,
        initialLoadOfferLetters.action,
        setOfferLetterFilters.action,
        setOfferLetterTableSort.action,
    ], withBackendErrorHandler(
        getMoreOfferLettersSaga,
        getMoreOfferLetterAction.error,
        'Unable to fetch offer letters',
    ));
}

const getCommonOfferLetterData = (payload: IOfferLetterFormValues): ICreateOfferRequest => {
    const customFieldValues = [] as string[];
    if (payload.jobOrderId) {
        customFieldValues.push(payload.jobOrderId);
    }
    if (payload.departmentId) {
        customFieldValues.push(payload.departmentId);
    }
    return {
        first_name: payload.first_name,
        last_name: payload.last_name,
        pay_rate_value: parseFloat(payload.payRate),
        pay_rate_type: payload.payRateType,
        pay_type: payload.payType,
        custom_field_value_ids: customFieldValues,
        start_date: payload.startDate,
        cell_phone: payload.phone,
        managers: payload.approvers
            .filter(ap => ap !== null)
            .map((ap, index) => {
                return {
                    user_id: ap?.id || '',
                    manager_level: index + 1,
                };
            }),
        offer_letter_template_id: payload.template || undefined,
        range_of_hours: payload.hours || undefined,
        employment_type: payload.employeeType || undefined,
        working_conditions: payload.workingConditions || '',
        working_conditions_id: payload.workingConditionsTemplate?.id || undefined,
        physical_demands: payload.physicalDemands || '',
        physical_demands_id: payload.physicalDemandsTemplate?.id || undefined,
        background_checks: payload.backgroundChecks || '',
        background_checks_id: payload.backgroundChecksTemplate?.id || undefined,
        employee_number: payload.employee_number || undefined,
    };
};

function* sentOfferToast(offerLetter: IOfferLetter) {
    const userName = getUserName(offerLetter);
    const isWaitingApproval = offerLetter.status.slug === OfferLetterStatusSlug.PendingPayRateApproval;
    const userMessageStart = userName ? `${userName}'s offer letter` : 'Offer letter';
    const userMessage = isWaitingApproval
        ? `${userMessageStart} successfully sent to the Regional Manager for pay rate approval. Once approved, it will automatically be sent to the employee.`
        : `${userMessageStart} was successfully sent.`;
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: userMessage,
        autoHideDuration: 5000,
    }));
}

function* createOfferSaga({ payload }: ReturnType<typeof createOffer.init>) {
    let createdOfferLetter;
    const commonOfferData = getCommonOfferLetterData(payload);
    if (payload.userId) {
        const createOfferPayload: ICreateEmployeeOfferLetterBackend = {
            user_id: payload.userId,
            ...commonOfferData,
        };
        createdOfferLetter = yield call(offerLetterApi.createEmployeeOffer, createOfferPayload);
    } else {
        const createOfferPayload: ICreateOfferLetterBackend = {
            email: payload.email,
            ...commonOfferData,
        };
        createdOfferLetter = yield call(offerLetterApi.createOffer, createOfferPayload);
    }
    yield put(createOffer.success(createdOfferLetter));
    yield* sentOfferToast(createdOfferLetter);
}

function* createOfferWatcher() {
    yield takeLatestActionByString(createOffer.initType, withBackendErrorHandler(
        createOfferSaga, createOffer.error, 'Offer wasn\'t created',
    ));
}

function* editOfferSaga({ payload: { id, values } }: ReturnType<typeof editOffer.init>) {
    const offerPayload: ICreateOfferLetterBackend = {
        email: values.email,
        ...getCommonOfferLetterData(values),
    };
    const newOfferLetter = yield call(offerLetterApi.updateOfferAndResend, id, offerPayload);

    yield put(editOffer.success(newOfferLetter));
    yield* sentOfferToast(newOfferLetter);
}

function* editOfferWatcher() {
    yield takeLatestActionByString(editOffer.initType, withBackendErrorHandler(
        editOfferSaga, editOffer.error, 'Offer wasn\'t modified',
    ));
}

function* rescindOfferSaga({
    payload: { id, values: { rescissionReason, rescissionReasonText, doNotSendEmployeeEmail } },
}: ReturnType<typeof rescindOffer.init>) {
    const params: Partial<IUpdateOfferRequest> = {
        rescission_reason: rescissionReason,
        rescission_reason_custom_text: rescissionReasonText,
        status: OfferLetterStatusSlug.Rescinded,
        do_not_send_employee_email: doNotSendEmployeeEmail,
    };
    const rescindedOfferLetter = yield call(offerLetterApi.updateOfferLetter, id, params);

    yield put(rescindOffer.success(rescindedOfferLetter));
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: 'Offer letter was successfully rescinded',
        autoHideDuration: 5000,
    }));
}

function* rescindOfferWatcher() {
    yield takeLatestActionByString(rescindOffer.initType, withBackendErrorHandler(
        rescindOfferSaga, rescindOffer.error, 'Offer wasn\'t rescinded',
    ));
}

function* listOfferRouteWatcher() {
    yield takeLatest(
        [editOffer.successType, rescindOffer.successType],
        navigateWithClientSaga,
        routes.CLIENT.OFFER_LETTER.ROOT,
    );
}

function* getOfferLetterSaga({ payload: offerId }: ReturnType<typeof getOfferLetter.init>) {
    const offerLetter = yield* call(offerLetterApi.getOfferLetterById, offerId);
    yield put(getOfferLetter.success(offerLetter));
}

function* getOfferLetterWatcher() {
    yield takeLatest(
        getOfferLetter.initType,
        withBackendErrorHandler(getOfferLetterSaga, getOfferLetter.error, 'Unable to get offer letter'),
    );
}

function* viewOfferLetterSaga({ payload: offerId }: ReturnType<typeof viewOfferLetter>) {
    const offerLetter = yield select(selectOfferLetter(offerId));
    if (offerLetter && !offerLetter.viewed_by_employee_at) {
        const params = {
            viewed_by_employee_at: moment.utc().format(),
        };
        const updatedOfferLetter = yield* call(offerLetterApi.updateOfferLetter, offerId, params);
        yield put(updateOfferLetter.success(updatedOfferLetter));
    }
}

function* viewOfferLetterWatcher() {
    yield takeLatestActionByString(viewOfferLetter.action, viewOfferLetterSaga);
}

function* getMyEmployeeProfilesSaga() {
    const employeeProfiles = yield* call(offerLetterApi.getMyEmployeeProfiles);
    yield put(getMyEmployeeProfiles.success(employeeProfiles));
}

export function* getOwnOfferLettersSaga() {
    const userHasPermission = yield* select(selectIsUserHasPermission(Permission.ViewMyOffers));
    if (!userHasPermission) {
        return;
    }

    const offerLettersWithLinked = yield call(offerLetterApi.getAllOfferLetters, { self: true });

    const offerLetters = offerLettersWithLinked.offer_letters;

    yield put(setClients(offerLettersWithLinked.linked.clients));
    yield put(getMyOfferLetters.success(offerLetters));

    const salariedOfferClientIds: string[] = uniq(
        offerLetters
            .filter((offer: IOfferLetter) => offer.pay_type === PayType.Salaried)
            .map((offer: IOfferLetter) => offer.client_id),
    );
    yield all(salariedOfferClientIds.map((clientId: string) => put(getClientPaySettings.init(clientId))));
    const customFieldValueIds = offerLetters.reduce((mem: string[], offer: IOfferLetter) => {
        return [
            ...mem,
        ].concat(offer.custom_field_value_ids ?? []);
    }, [] as string[]).filter(Boolean);
    yield put(getCustomFieldValues.init({ custom_field_value_ids: customFieldValueIds }));

    yield spawn(
        withBackendErrorHandler(getMyEmployeeProfilesSaga, getMyEmployeeProfiles.error, 'Unable to get employee profiles'));

    const outstandingOfferLetter = orderBy(
        offerLetters.filter(
            (offerLetter: IOfferLetter) => offerLetter.status.slug === OfferLetterStatusSlug.Outstanding
                && !offerLetter.viewed_by_employee_at,
        ),
        [(letter: IOfferLetter) => moment(letter.offer_date).unix()],
        ['desc'],
    )[0];

    const { pathname } = browserHistory.location;
    if (
        outstandingOfferLetter?.id
        && !matchPath(
            pathname,
            {
                path: routes.EMPLOYEE_OFFER_LETTER.DETAIL,
                exact: true,
                strict: true,
            },
        )
    ) {
        browserHistory.push(generatePath(routes.EMPLOYEE_OFFER_LETTER.DETAIL, { id: outstandingOfferLetter.id }));
    }
}

function* getOwnOfferLettersWatcher() {
    yield takeLatest([
        authByToken.successType,
        authByPassword.successType,
        getMyOfferLetters.initType,
    ], withBackendErrorHandler(getOwnOfferLettersSaga, getMyOfferLetters.error, 'Unable to get offer letters'));
}

export function* updateOfferLetterStatusSaga(
    {
        payload: { id, status, rejectionReason },
    }: ReturnType<typeof updateOfferLetterStatus.init>,
) {

    const params: Partial<IUpdateOfferRequest> = {
        status,
        rejection_reason: rejectionReason || null,
    };

    const toastPrefix = 'Offer Letter';
    const actionName = status === OfferLetterStatusNameMap[OfferLetterStatusSlug.Accepted] ? 'Accepted' : 'Rejected';

    try {
        const updatedOfferLetter = yield* call(offerLetterApi.updateOfferLetter, id, params);
        yield put(updateOfferLetterStatus.success(updatedOfferLetter));
        yield put(setGlobalToast({
            severity: IModalSeverity.Success,
            title: `${toastPrefix} successfully ${actionName}`,
        }));
        browserHistory.push(routes.EMPLOYEE_OFFER_LETTER.ROOT);
    } catch (e) {
        yield put(updateOfferLetterStatus.error(e));
        const errorMessage = `${toastPrefix} was not ${actionName}`;
        yield put(setGlobalToast({
            severity: IModalSeverity.Error,
            title: errorMessage,
        }));
        logErrorWithCustomMessage(e, errorMessage);
    }
}

function* updateOfferLetterStatusWatcher() {
    yield takeLatestActionByString(updateOfferLetterStatus.initType, updateOfferLetterStatusSaga);
}

export function* updateOfferLetterSaga(
    {
        payload: { id, values },
    }: ReturnType<typeof updateOfferLetter.init>,
) {
    try {
        const updatedOfferLetter = yield* call(offerLetterApi.updateOfferLetter, id, values);
        yield put(updateOfferLetter.success(updatedOfferLetter));
        yield put(authTokenUpdate.init());
    } catch (e) {
        yield put(updateOfferLetter.error(e));
        const errorMessage = e?.response?.data?.error?.message ?? `Offer letter update error.`;
        yield put(setGlobalToast({
            severity: IModalSeverity.Error,
            title: errorMessage,
        }));
        logErrorWithCustomMessage(e, errorMessage);
    }
}

function* updateOfferLetterWatcher() {
    yield takeLatestActionByString(updateOfferLetter.initType, updateOfferLetterSaga);
}

export function* acceptOfferLetterTermsSaga({ payload: id }: ReturnType<typeof acceptOfferLetterTerms.init>) {
    try {
        const updatedOfferLetter = yield* call(offerLetterApi.acceptOfferLetterTerms, id);
        yield put(updateOfferLetter.success(updatedOfferLetter));
        yield put(authTokenUpdate.init());
        yield put(acceptOfferLetterTerms.success());
    } catch (e) {
        yield put(acceptOfferLetterTerms.error(e));
        const errorMessage = e?.response?.data?.error?.message ?? `Accept offer letter terms error.`;
        yield put(setGlobalToast({
            severity: IModalSeverity.Error,
            title: errorMessage,
        }));
        logErrorWithCustomMessage(e, errorMessage);
    }
}

function* acceptOfferLetterTermsSagaWatcher() {
    yield takeLatestActionByString(acceptOfferLetterTerms.initType, acceptOfferLetterTermsSaga);
}

export function* onboardMyEmployeeProfileSaga(
    {
        payload,
    }: ReturnType<typeof onboardMyEmployeeProfile.init>,
) {
    try {
        const employeeProfile = yield* call(offerLetterApi.onboardMyEmployeeProfile,
            payload.clientId, payload.onboardingData);
        yield put(onboardMyEmployeeProfile.success(employeeProfile));
        yield put(getOfferLetter.init(payload.onboardingData.offer_letter_id));
        // yield put(setOnboardingStep(OnboardingSteps.AvionteOnboarding));
    } catch (e) {
        yield put(onboardMyEmployeeProfile.error(e));
        let errorMessage = 'Profile onboard error';
        const serverErrorMessage = e.response?.data?.error?.message;
        errorMessage = `${errorMessage}${ isString(serverErrorMessage) ? `: ${serverErrorMessage}` : '.'}`;
        yield put(setGlobalToast({
            severity: IModalSeverity.Error,
            title: errorMessage,
        }));
        logErrorWithCustomMessage(e, errorMessage);
    }
}

function* onboardMyEmployeeProfileWatcher() {
    yield takeLatestActionByString(onboardMyEmployeeProfile.initType, onboardMyEmployeeProfileSaga);
}

export function* getMyEmployeeProfileSaga({
    payload: clientId,
}: ReturnType<typeof getMyEmployeeProfile.init>) {
    try {
        const employeeProfile = yield* call(offerLetterApi.getMyEmployeeProfile, clientId);
        yield put(getMyEmployeeProfile.success(employeeProfile));
    } catch (e) {
        if (e?.response?.status !== 404) {
            logErrorWithCustomMessage(e, `Cannot get own employee profile`);
        }
        yield put(getMyEmployeeProfile.error(e));
    }
}

function* getMyEmployeeProfileWatcher() {
    yield takeLatestActionByString(getMyEmployeeProfile.initType, getMyEmployeeProfileSaga);
}

function* checkOfferLetterPdfSaga({ payload: {
    id,
    hasGlobalToaster = false,
} }: ReturnType<typeof checkOfferLetterPdf.init>) {
    try {
        yield* call(offerLetterApi.checkOfferLetterPdf, id);
        yield* put(checkOfferLetterPdf.success({ id }));
    } catch (e) {
        if (hasGlobalToaster) {
            yield* put(setGlobalToast({
                severity: IModalSeverity.Error,
                title: 'Document generation is in progress. Please try again later.',
                autoHideDuration: autoHideDefaultDuration * 2,
            }));
        }
        yield* put(checkOfferLetterPdf.error({ id, error: e }));
    }
}

function* checkOfferLetterPdfWatcher() {
    yield takeEvery(checkOfferLetterPdf.initType, checkOfferLetterPdfSaga);
}

function* checkEndOfAssignmentPdfStatusSaga({ payload: {
    id,
    hasGlobalToaster = false,
} }: ReturnType<typeof checkEndOfAssignmentPdfStatus.init>) {
    try {
        yield* call(offerLetterApi.checkEndOfAssignmentPdf, id);
        yield* put(checkEndOfAssignmentPdfStatus.success({ id }));
    } catch (e) {
        if (hasGlobalToaster) {
            yield* put(setGlobalToast({
                severity: IModalSeverity.Error,
                title: 'Document generation is in progress. Please try again later.',
                autoHideDuration: autoHideDefaultDuration * 2,
            }));
        }
        yield* put(checkEndOfAssignmentPdfStatus.error());
    }
}

function* checkEndOfAssignmentWatcher() {
    yield takeEvery(checkEndOfAssignmentPdfStatus.initType, checkEndOfAssignmentPdfStatusSaga);
}

const getEmployeeProfilesWatcher = getLoadEntitiesByRequestSagaWatcher(
    getEmployeeProfiles,
    offerLetterApi.getEmployeeProfiles,
    'employee profiles',
);

function* getOfferLettersSaga({ payload }: ReturnType<typeof getOfferLetters.init>) {
    yield* getOfferLettersWithLinkedSaga(payload);
}

function* getOfferLettersWatcher() {
    yield takeLatest(
        getOfferLetters.initType,
        withBackendErrorHandler(
            getOfferLettersSaga,
            getOfferLetters.error,
            'Unable to fetch offer letters',
        ),
    );
}

function* rejectPayRateOfferSaga({ payload }: ReturnType<typeof rejectOfferPayRange.init>) {
    for (const offer of payload) {
        const { offerId, reason } = offer;
        const params: Partial<IUpdateOfferRequest> = {
            pay_rate_rejection_reason: reason,
            status: OfferLetterStatusSlug.RejectedPayRate,
        };
        const updatedOfferLetter = yield* call(offerLetterApi.updateOfferLetter, offerId, params);
        yield put(updateOfferLetter.success(updatedOfferLetter));
    }

    yield put(rejectOfferPayRange.success());
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: 'Offer letter was successfully rejected',
    }));
    yield put(initialLoadOfferLetters());
    const clientId = yield select(selectCurrentClientId);
    browserHistory.push(generatePath(routes.CLIENT.OFFER_LETTER.ROOT, { client_id: clientId }));
}

function* rejectPayRateOfferWatcher() {
    yield takeLatestActionByString(rejectOfferPayRange.initType, withBackendErrorHandler(
        rejectPayRateOfferSaga, rejectOfferPayRange.error, 'Offer wasn\'t rejected',
    ));
}

function* approvePayRateOfferSaga({ payload }: ReturnType<typeof approveOfferPayRange.init>) {
    for (const offerId of payload) {
        const params: Partial<IUpdateOfferRequest> = {
            status: OfferLetterStatusSlug.Outstanding,
        };
        const updatedOfferLetter = yield* call(offerLetterApi.updateOfferLetter, offerId, params);
        yield put(updateOfferLetter.success(updatedOfferLetter));
    }

    yield put(approveOfferPayRange.success());
    yield put(setGlobalToast({
        severity: IModalSeverity.Success,
        title: 'Offer letter was successfully approved',
    }));
    yield put(initialLoadOfferLetters());
    const clientId = yield select(selectCurrentClientId);
    browserHistory.push(generatePath(routes.CLIENT.OFFER_LETTER.ROOT, { client_id: clientId }));
}

function* approvePayRateOfferWatcher() {
    yield takeLatestActionByString(approveOfferPayRange.initType, withBackendErrorHandler(
        approvePayRateOfferSaga, approveOfferPayRange.error, 'Offer wasn\'t approved',
    ));
}

export default [
    createOfferWatcher,
    editOfferWatcher,
    rescindOfferWatcher,
    listOfferRouteWatcher,
    getOfferLetterWatcher,
    getMoreOfferLettersWatcher,
    getOwnOfferLettersWatcher,
    updateOfferLetterStatusWatcher,
    updateOfferLetterWatcher,
    acceptOfferLetterTermsSagaWatcher,
    onboardMyEmployeeProfileWatcher,
    getMyEmployeeProfileWatcher,
    viewOfferLetterWatcher,
    checkOfferLetterPdfWatcher,
    checkEndOfAssignmentWatcher,
    rejectPayRateOfferWatcher,
    approvePayRateOfferWatcher,
    getEmployeeProfilesWatcher,
    getOfferLettersWatcher,
    ...lookupSagas,
    ...offerLetterTemplateSagas,
];
